import {useLocation, useNavigation} from '@remix-run/react'
import {createContext, createRef, memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'

interface PanelScrollContextType {
  saveScrollPosition: (key: string, position: number) => void
  getScrollPosition: (key: string) => number
}

const PanelScrollContext = createContext<PanelScrollContextType | null>(null)

export function PanelScrollProvider({children}: {children: React.ReactNode}) {
  const [positions] = useState(() => new Map<string, number>())
  const saveScrollPosition = useCallback(
    (key: string, position: number) => void positions.set(key, position),
    [positions],
  )
  const getScrollPosition = useCallback((key: string) => positions.get(key) ?? 0, [positions])
  const value = useMemo(() => ({saveScrollPosition, getScrollPosition}), [saveScrollPosition, getScrollPosition])

  return <PanelScrollContext.Provider value={value}>{children}</PanelScrollContext.Provider>
}

export interface ScrollablePanelProps extends React.HTMLAttributes<HTMLDivElement> {
  slot: string
  ignoreSearchParams?: boolean
  children: React.ReactNode
}

export const ScrollablePanel = memo(function ScrollablePanel({
  slot,
  ignoreSearchParams = false,
  children,
  ...rest
}: ScrollablePanelProps) {
  const ref = createRef<HTMLDivElement>()
  const scroll = useContext(PanelScrollContext)
  const location = useLocation()
  const navigation = useNavigation()

  const scrollKey = useMemo(
    () => (ignoreSearchParams ? `${slot}:${location.pathname}` : `${slot}:${location.pathname}:${location.key}`),
    [location.pathname, location.key, slot, ignoreSearchParams],
  )
  const lastRestored = useRef<string | null>(null)

  useEffect(() => {
    if (lastRestored.current === scrollKey || !ref.current || !scroll) return
    const position = scroll.getScrollPosition(scrollKey) ?? 0
    ref.current.scrollTo(0, position)
    lastRestored.current = scrollKey
  }, [ref, scroll, scrollKey])

  useEffect(() => {
    if (!ref.current || !scroll) return
    if (navigation.state === 'loading') {
      const position = ref.current.scrollTop ?? 0
      scroll.saveScrollPosition(scrollKey, position)
    }
  }, [ref, navigation.state, scrollKey, scroll])

  return (
    <div {...rest} ref={ref}>
      {children}
    </div>
  )
})
